import * as React from "react";
import Layout from "../../components/layout";
import Seo from "../../components/seo";

import UseMediaQuery from '../../scripts/utilResponsive';
import AdsenseAd from "../../components/flex/other/AdsenseAd";

import * as styles from '../../styles/desktop/other/profitTablesPage.module.css';

// Desktop
import DocumentForgeryTable from "../../components/desktop/other/DocumentForgeryTable";
import WeedFarmTable from "../../components/desktop/other/WeedFarmTable";
import CounterfeitCashTable from "../../components/desktop/other/CounterfeitCashTable";
import MethLabTable from "../../components/desktop/other/MethLabTable";
import CocaineLockupTable from "../../components/desktop/other/CocaineLockupTable";
import BunkerTable from "../../components/desktop/other/BunkerTable";
import NightclubTable from "../../components/desktop/other/NightclubTable";

// Mobile
import DocumentForgeryTableMobile from "../../components/mobile/other/DocumentForgeryTableMobile";
import WeedFarmTableMobile from "../../components/mobile/other/WeedFarmTableMobile";
import CounterfeitCashTableMobile from "../../components/mobile/other/CounterfeitCashTableMobile";
import MethLabTableMobile from "../../components/mobile/other/MethLabTableMobile";
import CocaineLockupTableMobile from "../../components/mobile/other/CocaineLockupTableMobile";
import BunkerTableMobile from "../../components/mobile/other/BunkerTableMobile";
import NightclubTableMobile from "../../components/mobile/other/NightclubTableMobile";

function ProfitTables() {
  // Determine mobile device or not
  const isBreakpoint = UseMediaQuery(768);

  return (
    <Layout>
      <Seo title="Profit Tables" description="Data tables that contain GTA Online business statistics used to caluclate profits." />
        <section className={styles.Container}>
          <div>
            { isBreakpoint ? (
              <div>
                <h1 className={styles.PageTitle}>GTA Online Business Profit Tables</h1>
                <AdsenseAd/>
                <DocumentForgeryTableMobile/>
                <AdsenseAd/>
                <WeedFarmTableMobile/>
                <AdsenseAd/>
                <CounterfeitCashTableMobile/>
                <AdsenseAd/>
                <MethLabTableMobile/>
                <AdsenseAd/>
                <CocaineLockupTableMobile/>
                <AdsenseAd/>
                <BunkerTableMobile/>
                <AdsenseAd/>
                <NightclubTableMobile/>
              </div>
            ) : (
              <div>
                <h1 className={styles.PageTitle}>GTA Online Business Profit Tables</h1>
                <AdsenseAd/>
                <DocumentForgeryTable/>
                <AdsenseAd/>
                <WeedFarmTable/>
                <AdsenseAd/>
                <CounterfeitCashTable/>
                <AdsenseAd/>
                <MethLabTable/>
                <AdsenseAd/>
                <CocaineLockupTable/>
                <AdsenseAd/>
                <BunkerTable/>
                <AdsenseAd/>
                <NightclubTable/>
              </div>
            )}
          </div>
        </section>
    </Layout>
  )
}

export default ProfitTables;
