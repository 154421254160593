import React from 'react';
import AdSense from 'react-adsense';

import * as ad from '../../../styles/ads.module.css';

function AdsenseAd() {
    return (
        <div className={ad.Container} id="ad">
            {/* auto full width responsive ads */}
            <AdSense.Google
                client='ca-pub-5234651621117566'
                slot='8655171133'
                style={{ display: 'block' }}
                format='auto'
                responsive='true'
            />
        </div>
    )
}

export default AdsenseAd;
