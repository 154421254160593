import React from 'react';

import * as styles from '../../../styles/desktop/other/profitTables.module.css';

function WeedFarmTable() {
    return (
        <div className={styles.Container}>
          <span className={styles.Anchor} id="weed-farm"></span>
          <h1>Weed Farm <span>Profit Table</span></h1>
          <table className={styles.ContentTable}>
            <thead>
              <tr>
                <th>Upgrade</th>
                <th>Upgrade Cost</th>
                <th>Unit Profit</th>
                <th>Minutes per Unit</th>
                <th>Capacity</th>
                <th>Sell Local</th>
                <th>Sell Remote</th>
                <th>Profit per Hour</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>No Upgrade</td>
                <td>$0</td>
                <td>$1,500</td>
                <td>6</td>
                <td>80</td>
                <td>$120,000</td>
                <td>$180,000</td>
                <td>$19,500</td>
              </tr>
              <tr>
                <td>Security Upgrade</td>
                <td>$313,500</td>
                <td>$1,500</td>
                <td>6</td>
                <td>80</td>
                <td>$120,000</td>
                <td>$180,000</td>
                <td>$19,500</td>
              </tr>
              <tr>
                <td>Equipment Upgrade</td>
                <td>$990,000</td>
                <td>$1,800</td>
                <td>5</td>
                <td>80</td>
                <td>$144,000</td>
                <td>$216,000</td>
                <td>$28,500</td>
              </tr>
              <tr>
                <td>Staff Upgrade</td>
                <td>$273,000</td>
                <td>$1,800</td>
                <td>5</td>
                <td>80</td>
                <td>$144,000</td>
                <td>$216,000</td>
                <td>$28,500</td>
              </tr>
              <tr className={styles.ActiveRow}>
                <td>Equipment/Staff Upgrade</td>
                <td>$1,263,000</td>
                <td>$2,100</td>
                <td>4</td>
                <td>80</td>
                <td>$168,000</td>
                <td>$252,000</td>
                <td>$41,500</td>
              </tr>
              <tr>
                <td>All Upgrades</td>
                <td>$1,576,500</td>
                <td>$2,100</td>
                <td>4</td>
                <td>80</td>
                <td>$168,000</td>
                <td>$252,000</td>
                <td>$41,500</td>
              </tr>
            </tbody>
          </table>
        </div>
    )
}

export default WeedFarmTable;
