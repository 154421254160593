import React from 'react';

import * as styles from '../../../styles/desktop/other/profitTables.module.css';

function CounterfeitCashTableMobile() {
    return (
        <div className={styles.Container}>
          <span className={styles.Anchor} id="counterfeit-cash"></span>
          <h1>Counterfeit Cash <span>Profit Table</span></h1>
          <table className={styles.ContentTable}>
            <thead>
              <tr>
                <th>Upgrade</th>
                <th>Upgrade Cost</th>
                <th>Unit Profit</th>
                <th>Minutes per Unit</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>No Upgrade</td>
                <td>$0</td>
                <td>$3,500</td>
                <td>12</td>
              </tr>
              <tr>
                <td>Security Upgrade</td>
                <td>$456,000</td>
                <td>$3,500</td>
                <td>12</td>
              </tr>
              <tr>
                <td>Equipment Upgrade</td>
                <td>$880,000</td>
                <td>$4,200</td>
                <td>10</td>
              </tr>
              <tr>
                <td>Staff Upgrade</td>
                <td>$273,000</td>
                <td>$4,200</td>
                <td>10</td>
              </tr>
              <tr className={styles.ActiveRow}>
                <td>Equipment/Staff Upgrade</td>
                <td>$1,153,000</td>
                <td>$4,900</td>
                <td>8</td>
              </tr>
              <tr>
                <td>All Upgrades</td>
                <td>$1,609,000</td>
                <td>$4,900</td>
                <td>8</td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th>Capacity</th>
                <th>Sell Local</th>
                <th>Sell Remote</th>
                <th>Profit per Hour</th>
              </tr>
            </thead>
            <tbody className={styles.tbody2}>
              <tr>
                <td>40</td>
                <td>$140,000</td>
                <td>$210,000</td>
                <td>$22,500</td>
              </tr>
              <tr>
                <td>40</td>
                <td>$140,000</td>
                <td>$210,000</td>
                <td>$22,500</td>
              </tr>
              <tr>
                <td>40</td>
                <td>$168,000</td>
                <td>$252,000</td>
                <td>$32,000</td>
              </tr>
              <tr>
                <td>40</td>
                <td>$168,000</td>
                <td>$252,000</td>
                <td>$32,000</td>
              </tr>
              <tr className={styles.ActiveRow}>
                <td>40</td>
                <td>$196,000</td>
                <td>$294,000</td>
                <td>$48,000</td>
              </tr>
              <tr>
                <td>40</td>
                <td>$196,000</td>
                <td>$294,000</td>
                <td>$48,000</td>
              </tr>
            </tbody>
          </table>
        </div>
    )
}

export default CounterfeitCashTableMobile;
