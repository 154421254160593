import React from 'react';

import * as styles from '../../../styles/desktop/other/profitTables.module.css';

function NightclubTableMobile() {
    return (
        <div className={styles.Container}>
          <span className={styles.Anchor} id="nightclub"></span>
          <h1>Nightclub <span>Profit Table</span></h1>
          <table className={styles.ContentTable}>
            <thead>
              <tr>
                <th>Business</th>
                <th>Equipment Upgrade</th>
                <th>Minutes per Unit [Stock]</th>
                <th>Minutes per Unit [Upgraded]</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Document Forgery</td>
                <td>$1,425,000</td>
                <td>30</td>
                <td>15</td>
              </tr>
              <tr>
                <td>Weed Farm</td>
                <td>$1,425,000</td>
                <td>80</td>
                <td>40</td>
              </tr>
              <tr>
                <td>Counterfeit Cash</td>
                <td>$1,425,000</td>
                <td>60</td>
                <td>30</td>
              </tr>
              <tr>
                <td>Bunker</td>
                <td>$1,425,000</td>
                <td>80</td>
                <td>40</td>
              </tr>
              <tr>
                <td>Meth Lab</td>
                <td>$1,425,000</td>
                <td>120</td>
                <td>60</td>
              </tr>
              <tr>
                <td>Cocaine Lockup</td>
                <td>$1,425,000</td>
                <td>240</td>
                <td>120</td>
              </tr>
              <tr>
                <td>CEO Crates</td>
                <td>$1,425,000</td>
                <td>140</td>
                <td>70</td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th>Unit Profit</th>
                <th>Sell Full</th>
                <th>Capacity</th>
                <th>Profit per Hour</th>
              </tr>
            </thead>
            <tbody className={styles.tbody2}>
              <tr>
                <td>$1,000</td>
                <td>$60,000</td>
                <td>60</td>
                <td>$67</td>
              </tr>
              <tr>
                <td>$1,500</td>
                <td>$120,000</td>
                <td>80</td>
                <td>$56</td>
              </tr>
              <tr>
                <td>$3,500</td>
                <td>$140,000</td>
                <td>40</td>
                <td>$175</td>
              </tr>
              <tr>
                <td>$5,000</td>
                <td>$500,000</td>
                <td>100</td>
                <td>$75</td>
              </tr>
              <tr>
                <td>$8,500</td>
                <td>$170,000</td>
                <td>20</td>
                <td>$425</td>
              </tr>
              <tr>
                <td>$20,000</td>
                <td>$200,000</td>
                <td>10</td>
                <td>$1,000</td>
              </tr>
              <tr>
                <td>$10,000</td>
                <td>$500,000</td>
                <td>50</td>
                <td>$171</td>
              </tr>
            </tbody>
          </table>
        </div>
    )
}

export default NightclubTableMobile;
