import React from 'react';

import * as styles from '../../../styles/desktop/other/profitTables.module.css';

function BunkerTableMobile() {
    return (
        <div className={styles.Container}>
          <span className={styles.Anchor} id="bunker"></span>
          <h1>Bunker <span>Profit Table</span></h1>
          <table className={styles.ContentTable}>
            <thead>
              <tr>
                <th>Upgrade</th>
                <th>Upgrade Cost</th>
                <th>Unit Profit</th>
                <th>Minutes per Unit</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>No Upgrade</td>
                <td>$0</td>
                <td>$5,000</td>
                <td>10</td>
              </tr>
              <tr>
                <td>Security Upgrade</td>
                <td>$351,000</td>
                <td>$5,000</td>
                <td>10</td>
              </tr>
              <tr>
                <td>Equipment Upgrade</td>
                <td>$1,155,000</td>
                <td>$6,000</td>
                <td>8.5</td>
              </tr>
              <tr>
                <td>Staff Upgrade</td>
                <td>$598,500</td>
                <td>$6,000</td>
                <td>8.5</td>
              </tr>
              <tr className={styles.ActiveRow}>
                <td>Equipment/Staff Upgrade</td>
                <td>$1,753,500</td>
                <td>$7,000</td>
                <td>7</td>
              </tr>
              <tr>
                <td>All Upgrades</td>
                <td>$2,104,500</td>
                <td>$7,000</td>
                <td>7</td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th>Capacity</th>
                <th>Sell Local</th>
                <th>Sell Remote</th>
                <th>Profit per Hour</th>
              </tr>
            </thead>
            <tbody className={styles.tbody2}>
              <tr>
                <td>100</td>
                <td>$500,000</td>
                <td>$750,000</td>
                <td>$39,000</td>
              </tr>
              <tr>
                <td>100</td>
                <td>$500,000</td>
                <td>$750,000</td>
                <td>$39,000</td>
              </tr>
              <tr>
                <td>100</td>
                <td>$600,000</td>
                <td>$900,000</td>
                <td>$55,000</td>
              </tr>
              <tr>
                <td>100</td>
                <td>$600,000</td>
                <td>$900,000</td>
                <td>$55,000</td>
              </tr>
              <tr className={styles.ActiveRow}>
                <td>100</td>
                <td>$700,000</td>
                <td>$1,050,000</td>
                <td>$79,000</td>
              </tr>
              <tr>
                <td>100</td>
                <td>$700,000</td>
                <td>$1,050,000</td>
                <td>$79,000</td>
              </tr>
            </tbody>
          </table>
        </div>
    )
}

export default BunkerTableMobile;
