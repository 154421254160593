import React from 'react';

import * as styles from '../../../styles/desktop/other/profitTables.module.css';

function MethLabTable() {
    return (
        <div className={styles.Container}>
          <span className={styles.Anchor} id="meth-lab"></span>
          <h1>Math Lab <span>Profit Table</span></h1>
          <table className={styles.ContentTable}>
            <thead>
              <tr>
                <th>Upgrade</th>
                <th>Upgrade Cost</th>
                <th>Unit Profit</th>
                <th>Minutes per Unit</th>
                <th>Capacity</th>
                <th>Sell Local</th>
                <th>Sell Remote</th>
                <th>Profit per Hour</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>No Upgrade</td>
                <td>$0</td>
                <td>$8,500</td>
                <td>30</td>
                <td>20</td>
                <td>$170,000</td>
                <td>$255,000</td>
                <td>$21,000</td>
              </tr>
              <tr>
                <td>Security Upgrade</td>
                <td>$513,000</td>
                <td>$8,500</td>
                <td>30</td>
                <td>20</td>
                <td>$170,000</td>
                <td>$255,000</td>
                <td>$21,000</td>
              </tr>
              <tr>
                <td>Equipment Upgrade</td>
                <td>$1,100,000</td>
                <td>$10,200</td>
                <td>24</td>
                <td>20</td>
                <td>$204,000</td>
                <td>$306,000</td>
                <td>$32,000</td>
              </tr>
              <tr>
                <td>Staff Upgrade</td>
                <td>$331,500</td>
                <td>$10,200</td>
                <td>24</td>
                <td>20</td>
                <td>$204,000</td>
                <td>$306,000</td>
                <td>$32,000</td>
              </tr>
              <tr className={styles.ActiveRow}>
                <td>Equipment/Staff Upgrade</td>
                <td>$1,431,500</td>
                <td>$11,900</td>
                <td>18</td>
                <td>20</td>
                <td>$238,000</td>
                <td>$357,000</td>
                <td>$51,500</td>
              </tr>
              <tr>
                <td>All Upgrades</td>
                <td>$1,944,500</td>
                <td>$11,900</td>
                <td>18</td>
                <td>20</td>
                <td>$238,000</td>
                <td>$357,000</td>
                <td>$51,500</td>
              </tr>
            </tbody>
          </table>
        </div>
    )
}

export default MethLabTable;
