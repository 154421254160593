import React from 'react';

import * as styles from '../../../styles/desktop/other/profitTables.module.css';

function CocaineLockupTableMobile() {
    return (
        <div className={styles.Container}>
          <span className={styles.Anchor} id="cocaine-lockup"></span>
          <h1>Cocaine Lockup <span>Profit Table</span></h1>
          <table className={styles.ContentTable}>
            <thead>
              <tr>
                <th>Upgrade</th>
                <th>Upgrade Cost</th>
                <th>Unit Profit</th>
                <th>Minutes per Unit</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>No Upgrade</td>
                <td>$0</td>
                <td>$20,000</td>
                <td>50</td>
              </tr>
              <tr>
                <td>Security Upgrade</td>
                <td>$570,000</td>
                <td>$20,000</td>
                <td>50</td>
              </tr>
              <tr>
                <td>Equipment Upgrade</td>
                <td>$935,000</td>
                <td>$24,000</td>
                <td>40</td>
              </tr>
              <tr>
                <td>Staff Upgrade</td>
                <td>$390,000</td>
                <td>$24,000</td>
                <td>40</td>
              </tr>
              <tr className={styles.ActiveRow}>
                <td>Equipment/Staff Upgrade</td>
                <td>$1,325,000</td>
                <td>$28,000</td>
                <td>30</td>
              </tr>
              <tr>
                <td>All Upgrades</td>
                <td>$1,895,000</td>
                <td>$28,000</td>
                <td>30</td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th>Capacity</th>
                <th>Sell Local</th>
                <th>Sell Remote</th>
                <th>Profit per Hour</th>
              </tr>
            </thead>
            <tbody className={styles.tbody2}>
              <tr>
                <td>10</td>
                <td>$200,000</td>
                <td>$300,000</td>
                <td>$25,000</td>
              </tr>
              <tr>
                <td>10</td>
                <td>$200,000</td>
                <td>$300,000</td>
                <td>$25,000</td>
              </tr>
              <tr>
                <td>10</td>
                <td>$240,000</td>
                <td>$360,000</td>
                <td>$30,000</td>
              </tr>
              <tr>
                <td>10</td>
                <td>$240,000</td>
                <td>$360,000</td>
                <td>$30,000</td>
              </tr>
              <tr className={styles.ActiveRow}>
                <td>10</td>
                <td>$280,000</td>
                <td>$420,000</td>
                <td>$35,000</td>
              </tr>
              <tr>
                <td>10</td>
                <td>$280,000</td>
                <td>$420,000</td>
                <td>$35,000</td>
              </tr>
            </tbody>
          </table>
        </div>
    )
}

export default CocaineLockupTableMobile;
