import React from 'react';

import * as styles from '../../../styles/desktop/other/profitTables.module.css';

function DocumentForgeryTable() {
    return (
        <div className={styles.Container}>
          <span className={styles.Anchor} id="document-forgery"></span>
          <h1>Document Forgery <span>Profit Table</span></h1>
          <table className={styles.ContentTable}>
            <thead>
              <tr>
                <th>Upgrade</th>
                <th>Upgrade Cost</th>
                <th>Unit Profit</th>
                <th>Minutes per Unit</th>
                <th>Capacity</th>
                <th>Sell Local</th>
                <th>Sell Remote</th>
                <th>Profit per Hour</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>No Upgrade</td>
                <td>$0</td>
                <td>$1,000</td>
                <td>5</td>
                <td>60</td>
                <td>$60,000</td>
                <td>$90,000</td>
                <td>$16,000</td>
              </tr>
              <tr>
                <td>Security Upgrade</td>
                <td>$285,000</td>
                <td>$1,000</td>
                <td>5</td>
                <td>60</td>
                <td>$60,000</td>
                <td>$90,000</td>
                <td>$16,000</td>
              </tr>
              <tr>
                <td>Equipment Upgrade</td>
                <td>$550,000</td>
                <td>$1,200</td>
                <td>4</td>
                <td>60</td>
                <td>$72,000</td>
                <td>$108,000</td>
                <td>$24,500</td>
              </tr>
              <tr>
                <td>Staff Upgrade</td>
                <td>$195,000</td>
                <td>$1,200</td>
                <td>4</td>
                <td>60</td>
                <td>$72,000</td>
                <td>$108,000</td>
                <td>$24,500</td>
              </tr>
              <tr className={styles.ActiveRow}>
                <td>Equipment/Staff Upgrade</td>
                <td>$745,000</td>
                <td>$1,400</td>
                <td>3</td>
                <td>60</td>
                <td>$84,000</td>
                <td>$126,000</td>
                <td>$38,500</td>
              </tr>
              <tr>
                <td>All Upgrades</td>
                <td>$1,030,000</td>
                <td>$1,400</td>
                <td>3</td>
                <td>60</td>
                <td>$84,000</td>
                <td>$126,000</td>
                <td>$38,500</td>
              </tr>
            </tbody>
          </table>
        </div>
    )
}

export default DocumentForgeryTable;
